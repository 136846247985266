import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Showcase.css';
import { useCart } from '../../context/CartContext';

const Showcase = () => {
  const [products, setProducts] = useState([]);
  const { addToCart } = useCart();

  useEffect(() => {
    axios.get('/api/products/')
      .then(response => setProducts(response.data))
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  return (
    <div className="showcase" id='showcase'>
      <h2>Showcase</h2>
      <div className="showcase-grid">
        {products.length === 0 ? (
          <p>No products available at the moment.</p>
        ) : (
          products.map((product) => (
            <div className="showcase-item" key={product.id}>
              <div className="showcase-iframe">
                <iframe src={product.url} title={product.title} />
              </div>
              <div className="showcase-content">
                <h3>{product.title}</h3>
                <p>{product.description}</p>
                <p>{product.price ? `$${product.price}` : 'Price not available'}</p>
                <button onClick={() => addToCart(product)}>Add to Cart</button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Showcase;