import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ConsultationBooking.css'; // Custom styles
import { csrfToken } from '../csrf'; // Import the CSRF token helper

const ConsultationBooking = ({ onBookingComplete }) => {
  const [slots, setSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Fetch available slots from the backend
    axios.get('http://127.0.0.1:8000/api/slots/')
      .then(response => setSlots(response.data))
      .catch(error => console.error('Error fetching slots:', error));
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);

    // Filter available slots for the selected date
    const formattedDate = date.toISOString().split('T')[0]; // 'YYYY-MM-DD'
    const filteredSlots = slots.filter(slot => slot.date === formattedDate);
    setAvailableSlots(filteredSlots);
  };

  const handleSubmit = () => {
    if (!selectedSlot) {
      alert('Please select a time slot.');
      return;
    }
    axios.post('http://127.0.0.1:8000/api/bookings/', {
      slot: selectedSlot.id,
      name: name,
      email: email,
    }, {
      headers: {
        'X-CSRFToken': csrfToken  // Include the CSRF token in the headers
      }
    }).then(response => {
      alert('Consultation booked successfully!');
      
      // Notify parent that booking is complete
      if (onBookingComplete) {
        onBookingComplete();  // Trigger the callback
      }
    }).catch(error => {
      console.error('Error booking consultation:', error);
      alert('Error booking consultation.');
    });
  };

  const isDateAvailable = (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    return slots.some(slot => slot.date === formattedDate && slot.is_available);
  };

  const highlightDates = slots.map(slot => ({
    date: new Date(slot.date),
    is_available: slot.is_available
  }));

  return (
    <div className="consultation-booking-container">
      <h3>Book a Consultation</h3>

      <div className="consultation-content">
        {/* Calendar with available/unavailable dates */}
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          inline
          highlightDates={[
            {
              "react-datepicker__day--highlighted-available": highlightDates
                .filter(slot => slot.is_available)
                .map(slot => slot.date),
            },
            {
              "react-datepicker__day--highlighted-unavailable": highlightDates
                .filter(slot => !slot.is_available)
                .map(slot => slot.date),
            },
          ]}
          dayClassName={date => 
            isDateAvailable(date) ? 'available' : 'unavailable'
          }
        />

        {/* Booking Form and Time Slots */}
        <div className="booking-form-container">
        <div className="timeslots">
            <h4>Select a Time Slot</h4>
            <select
                size="2"  // Limits how many options are shown at once, with scrollable behavior
                onChange={(e) => {
                const selectedSlot = availableSlots.find(slot => slot.id === parseInt(e.target.value));
                setSelectedSlot(selectedSlot);
                }}
                className="scrollable-select"
            >
                {availableSlots.map(slot => (
                <option key={slot.id} value={slot.id}>
                    {slot.start_time} - {slot.end_time}
                </option>
                ))}
            </select>
        </div>

          <div className="booking-form">
            <label>Name:</label>
            <input type="text" value={name} onChange={e => setName(e.target.value)} />

            <label>Email:</label>
            <input type="email" value={email} onChange={e => setEmail(e.target.value)} />

            {/* Submit Button */}
            <button className="submit-btn" onClick={handleSubmit}>Book Consultation</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultationBooking;